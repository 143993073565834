import router from "../components/Routes";
import { API_URL } from "../constants/urls";
import client from "../constants/apollo-client";
import { clearToken } from "../utils/token";
import { commonFetch } from "../utils/fetch";

const useLogout = () => {
  const logout = async () => {
    const res = await commonFetch(`${API_URL}/auth/logout`, {
      method: "POST",
    });
    if (!res.ok) {
      throw new Error("Error logging out.");
    }
    clearToken()
    router.navigate("/login");
    client.resetStore()
  };


  return { logout };
};

export { useLogout };
